import { type AppRouter } from '@hg-akademie-ban/vamos-viva-main';
import { QueryClient } from '@tanstack/react-query';
import { httpLink, loggerLink } from '@trpc/client';
import { createTRPCReact } from '@trpc/react-query';
import superjson from 'superjson';
import { getBackendConfig } from 'utils/BackendInfrastructure';
import { getItemFromSessionStorage, setItemToSessionStorage } from 'utils/sessionStorage';
import { v7 } from 'uuid';

const backendConfig = await getBackendConfig();
const appBackendUrl = backendConfig.success ? backendConfig.config.AppBackendUrl : 'http://localhost:4040';

const trpc = createTRPCReact<AppRouter>();

const trpcClient = (getToken: () => Promise<string | null>) =>
  trpc.createClient({
    links: [
      loggerLink(),
      httpLink({
        transformer: superjson,
        url: appBackendUrl,
        async headers() {
          const adminScopes = getItemFromSessionStorage('X-Viva-Admin-Scopes');
          const adminScopeHeader = adminScopes === null ? {} : { 'X-Viva-Admin-Scopes': JSON.stringify(adminScopes) };

          const xVivaRequestId = v7();
          setItemToSessionStorage('latest-X-Viva-Request-Id', xVivaRequestId);

          return {
            Authorization: `Bearer ${await getToken()}`,
            'X-Viva-Request-Id': v7(),
            ...adminScopeHeader,
          };
        },
        async fetch(url, options) {
          return await fetch(url, {
            ...options,
            credentials: 'include',
          });
        },
      }),
    ],
  });

const queryClient = new QueryClient();

export { queryClient, trpc, trpcClient };
