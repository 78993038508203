import { content } from './VaStatusChip.content';
import { Chip } from '@mui/material';
import { type SapStatus, type VivaStatus, vivaStatus as vivaStatusOptions } from 'dtos';

type VaStatusChipProps = {
  readonly sapStatus: SapStatus | null;
  readonly vivaStatus: VivaStatus | null;
};

const determineStatusText = (sapStatus: SapStatus | null, vivaStatus: VivaStatus | null): string => {
  if (sapStatus !== null) {
    return sapStatus;
  }

  switch (vivaStatus) {
    case vivaStatusOptions.INPLANUNG:
      return content.inPlanung;
    case vivaStatusOptions.ABGESCHLOSSEN:
      return content.abgeschlossen;
    case vivaStatusOptions.FREIGEGEBEN:
      return content.freigegeben;
    default:
      return content.noInfoAvailable;
  }
};

export const VaStatusChip: React.FC<VaStatusChipProps> = ({ sapStatus, vivaStatus }: VaStatusChipProps) => {
  const status = determineStatusText(sapStatus, vivaStatus);
  return <Chip color="primary" label={status} />;
};
