import { content } from './CalendarResourceOverview.content';
import {
  type CalendarEvent,
  CalendarExperteResource,
  CalendarRaumResource,
  type CalendarResource,
  CalendarSlotResource,
  isExperteResource,
  isRaumResource,
  isSlotResource,
  newSlot,
} from './helperClasses';
import { type MbscCalendarColor, type MbscResource } from '@mobiscroll/react';
import { eachDayOfInterval, endOfDay, startOfDay } from 'date-fns';
import {
  type BoughtAvailability,
  clusterzuordnung,
  type GetExpertenByThemaOutput,
  type GetOrteWithRaeumeOutput,
  type GetPlanungsinformationOutput,
  type GetStandortSlotsOutput,
  type ResourcenBlockungen,
  resourcenBlockungTypes,
} from 'dtos';
import { type TerminInfo } from 'utils/TerminInfo';

export type FilterParameters = {
  region: number[];
  standort: number[];
  orte: number[];
};

type SlotResourceGroup = {
  id: typeof resourcenBlockungTypes.SLOT;
  name: typeof content.calenderResourceGroupLabels.SLOT;
  children: CalendarSlotResource[];
} & MbscResource;

type RaumResourceGroup = {
  id: typeof resourcenBlockungTypes.RAUM;
  name: typeof content.calenderResourceGroupLabels.RAUM;
  children: CalendarRaumResource[];
} & MbscResource;

type ExperteResourceGroup = {
  id: typeof resourcenBlockungTypes.EXPERTE;
  name: typeof content.calenderResourceGroupLabels.EXPERTE;
  children: [
    {
      id: typeof clusterzuordnung.HAUPTTRAINER;
      name: typeof content.clusterzuordnungLabels.haupttrainer;
      children: CalendarExperteResource[];
    },
    {
      id: typeof clusterzuordnung.NEBENTRAINER;
      name: typeof content.clusterzuordnungLabels.nebentrainer;
      children: CalendarExperteResource[];
    },
    {
      id: typeof clusterzuordnung.ERSATZTRAINER;
      name: typeof content.clusterzuordnungLabels.ersatztrainer;
      children: CalendarExperteResource[];
    },
  ];
} & MbscResource;

export type CollapseInfo = {
  experte: boolean;
  raum: boolean;
  slot: boolean;
};

const getGroupDefaultValues = (): {
  experten: ExperteResourceGroup;
  raeume: RaumResourceGroup;
  slots: SlotResourceGroup;
} => {
  const experten: ExperteResourceGroup = {
    id: resourcenBlockungTypes.EXPERTE,
    name: content.calenderResourceGroupLabels.EXPERTE,
    collapsed: false,
    children: [
      {
        id: clusterzuordnung.HAUPTTRAINER,
        name: content.clusterzuordnungLabels.haupttrainer,
        children: [],
      },
      {
        id: clusterzuordnung.NEBENTRAINER,
        name: content.clusterzuordnungLabels.nebentrainer,
        children: [],
      },
      {
        id: clusterzuordnung.ERSATZTRAINER,
        name: content.clusterzuordnungLabels.ersatztrainer,
        children: [],
      },
    ],
  };

  const raeume: RaumResourceGroup = {
    id: resourcenBlockungTypes.RAUM,
    name: content.calenderResourceGroupLabels.RAUM,
    children: [],
    collapsed: false,
  };

  const slots: SlotResourceGroup = {
    id: resourcenBlockungTypes.SLOT,
    name: content.calenderResourceGroupLabels.SLOT,
    children: [],
    collapsed: false,
  };

  return {
    experten,
    raeume,
    slots,
  };
};

const getTrainerlistenWithRowNames = (
  experten: ExperteResourceGroup,
  planungsinformation: GetPlanungsinformationOutput,
): {
  haupttrainerliste: ExperteResourceGroup['children'][0];
  nebentrainerliste: ExperteResourceGroup['children'][1];
  ersatztrainerliste: ExperteResourceGroup['children'][2];
} => {
  const haupttrainerliste = experten.children.find((child) => child.id === clusterzuordnung.HAUPTTRAINER);
  const nebentrainerliste = experten.children.find((child) => child.id === clusterzuordnung.NEBENTRAINER);
  const ersatztrainerliste = experten.children.find((child) => child.id === clusterzuordnung.ERSATZTRAINER);

  if (typeof haupttrainerliste === 'undefined') {
    throw new TypeError('Haupttrainerliste nicht gefunden.');
  }

  if (typeof nebentrainerliste === 'undefined') {
    throw new TypeError('Nebentrainerliste nicht gefunden.');
  }

  if (typeof ersatztrainerliste === 'undefined') {
    throw new TypeError('Ersatztrainerliste nicht gefunden.');
  }

  const clusteranteilA = planungsinformation.clusteranteil?.split('/')[0];
  const clusteranteilB = planungsinformation.clusteranteil?.split('/')[1];

  haupttrainerliste.name += ` - Cluster ${clusteranteilA}%`;
  nebentrainerliste.name += ` - Cluster ${clusteranteilB}%`;
  ersatztrainerliste.name += ' - Cluster C';

  return {
    haupttrainerliste,
    nebentrainerliste,
    ersatztrainerliste,
  };
};

export const groupCalendarResources = (resources: CalendarResource[], collapseInfo: CollapseInfo, planungsinformation: GetPlanungsinformationOutput): MbscResource[] => {
  const { experten, raeume, slots } = getGroupDefaultValues();

  experten.collapsed = collapseInfo.experte;
  raeume.collapsed = collapseInfo.raum;
  slots.collapsed = collapseInfo.slot;

  const { haupttrainerliste, nebentrainerliste, ersatztrainerliste } = getTrainerlistenWithRowNames(experten, planungsinformation);

  for (const resource of resources) {
    switch (resource.type) {
      case resourcenBlockungTypes.EXPERTE: {
        const expertePlanungsinformation = planungsinformation.expertepool.find((experte) => experte.experteSapId === resource.data.experteSapId);

        const auslastung = expertePlanungsinformation?.auslastungProJahr.find((item) => item.jahr === new Date().getFullYear())?.auslastung;

        let print = expertePlanungsinformation?.vorname + ' ' + expertePlanungsinformation?.nachname;

        if (auslastung) {
          print += ' / ' + auslastung;
        }

        if (expertePlanungsinformation?.einsatzort) {
          print += ' / ' + expertePlanungsinformation.einsatzort;
        }

        if (expertePlanungsinformation?.individuelleProzentVerteilung) {
          print += ' / ' + expertePlanungsinformation.individuelleProzentVerteilung + '%';
        }

        if (expertePlanungsinformation?.ausschlusszeiten) {
          print += ' / Ausschlusszeiten: ' + expertePlanungsinformation.ausschlusszeiten;
        }

        resource.setName(print);

        if (resource.data.clusterzuordnung === clusterzuordnung.HAUPTTRAINER) {
          haupttrainerliste.children.push(resource);
        } else if (resource.data.clusterzuordnung === clusterzuordnung.NEBENTRAINER) {
          nebentrainerliste.children.push(resource);
        } else {
          ersatztrainerliste.children.push(resource);
        }

        break;
      }

      case resourcenBlockungTypes.RAUM:
        raeume.children.push(resource);
        break;
      case resourcenBlockungTypes.SLOT:
        slots.children.push(resource);
        break;
      default:
        break;
    }
  }

  const groups: Array<RaumResourceGroup | SlotResourceGroup | ExperteResourceGroup> = [raeume, slots, experten];

  return groups;
};

export const createCalendarResources = (
  expertepool: GetExpertenByThemaOutput,
  orteWithRaeume: GetOrteWithRaeumeOutput,
  slots: GetStandortSlotsOutput,
  terminInfo: TerminInfo,
  startDate: Date,
): CalendarResource[] => {
  const resources: CalendarResource[] = [];
  for (const ort of orteWithRaeume) {
    for (const raum of ort.raume) {
      resources.push(new CalendarRaumResource(terminInfo, raum));
    }
  }

  for (const experte of expertepool) {
    resources.push(new CalendarExperteResource(terminInfo, experte, startDate.getFullYear()));
  }

  for (const slot of slots) {
    resources.push(new CalendarSlotResource(terminInfo, slot));
  }

  resources.push(newSlot);

  return resources;
};

type CalendarColor = MbscCalendarColor & { start: Date; end: Date };

const CALENDAR_RESOURCE_BACKGROUND_COLOR = '#DAFAC2';

const createRaumColor = (raumResource: CalendarRaumResource, availability: BoughtAvailability): CalendarColor => ({
  start: startOfDay(availability.startDate),
  end: endOfDay(availability.endDate),
  resource: raumResource.id,
  background: CALENDAR_RESOURCE_BACKGROUND_COLOR,
});

const createSlotColor = (slotResource: CalendarSlotResource, start: Date, end: Date): CalendarColor => ({
  start,
  end,
  resource: slotResource.id,
  background: CALENDAR_RESOURCE_BACKGROUND_COLOR,
});

const createExperteColor = (experteResource: CalendarExperteResource): MbscCalendarColor => ({
  recurring: { repeat: 'daily' },
  resource: experteResource.id,
  background: CALENDAR_RESOURCE_BACKGROUND_COLOR,
});

const createEventColor = (date: Date) => ({
  start: startOfDay(date),
  end: endOfDay(date),
  background: '#C8D9FA',
});

export const createCalendarColors = (resources: CalendarResource[], events: CalendarEvent[]): MbscCalendarColor[] => {
  const colors: MbscCalendarColor[] = [];

  for (const resource of resources) {
    if (isRaumResource(resource)) {
      for (const availability of resource.data.boughtAvailabilities) {
        colors.push(createRaumColor(resource, availability));
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- TODO fix whatever causes this
    if (isSlotResource(resource) && resource.data?.start && resource.data?.end) {
      colors.push(createSlotColor(resource, resource.data.start, resource.data.end));
    }

    if (isExperteResource(resource)) {
      colors.push(createExperteColor(resource));
    }
  }

  for (const event of events) {
    if (event.isCurrentSelection) {
      for (const date of eachDayOfInterval(event)) {
        colors.push(createEventColor(date));
      }
    }
  }

  return colors;
};

export const getResourcenBlockungen = (events: CalendarEvent[]): ResourcenBlockungen => {
  const blockungen: ResourcenBlockungen = [];

  for (const event of events) {
    if (!event.isCurrentSelection) {
      continue;
    }

    for (const buchungsTag of eachDayOfInterval(event)) {
      if (event.type === resourcenBlockungTypes.SLOT) {
        const id = event.dataId;
        blockungen.push({
          standortSlotId: id > 0 ? id : null,
          ressourceSapId: event.standortSapId,
          type: event.type,
          buchungsTag,
        });
      } else {
        blockungen.push({
          ressourceSapId: event.dataId,
          type: event.type,
          buchungsTag,
        });
      }
    }
  }

  return blockungen;
};
