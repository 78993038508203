import { AlreadyCorrectMessageSingle, failBeMessage, FailCheckMessageSingle, failDbMessage, SuccessMessageSingle } from '../statusChangeMessages.utils';
import { MenuItem, Select, Stack, Typography } from '@mui/material';
import { AccordionWithHeading } from 'components/general/AccordionWithHeading';
import { Draggable } from 'components/general/dnd/Draggable';
import { EditButtonGroup } from 'components/general/EditButtonGroup';
import { Tablelike } from 'components/general/Tablelike';
import {
  type ChangeStatusForVeranstaltungenOutput,
  type Geschaeftsbereich,
  geschaeftsbereiche,
  type Veranstaltung,
  veranstaltungChangeFailedCause,
  type VivaStatus,
  vivaStatus as vivaStatusEnum,
} from 'dtos';
import { useSnackbar } from 'notistack';
import { type ReactElement, useEffect, useState } from 'react';
import { trpc } from 'trpc';
import { v4 } from 'uuid';

type TerminEigenschaftenCardProps = {
  readonly veranstaltung: Veranstaltung;
};

type TextOrSelectProps<T> = {
  readonly isEdit: boolean;
  readonly valueState: ReturnType<typeof useState<T | undefined>>;
  readonly selections: Array<{ value: VivaStatus | Geschaeftsbereich | undefined; name: string }>;
};

const TextOrSelect = <T,>(props: TextOrSelectProps<T>): ReactElement<TextOrSelectProps<T>> => {
  const [value, setValue] = props.valueState;

  if (!props.isEdit) {
    return <Typography component="span">{props.selections.find((sel) => sel.value === value)?.name ?? '-'}</Typography>;
  }

  return (
    <Select
      value={value}
      variant="standard"
      size="small"
      onChange={(event) => {
        setValue(event.target.value as T);
      }}
    >
      {props.selections.map((sel) => (
        <MenuItem key={v4()} value={sel.value}>
          {sel.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export const VeranstaltungEigenschaftenCard: React.FC<TerminEigenschaftenCardProps> = ({ veranstaltung }: TerminEigenschaftenCardProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isEditMode, setIsEditMode] = useState(false);
  const [vivaStatus, setVivaStatus] = useState<VivaStatus | undefined | null>();
  const [geschaeftsbereich, setGeschaeftsbereich] = useState<Geschaeftsbereich | undefined>(geschaeftsbereiche.KFF);

  const veranstaltungUtils = trpc.useUtils().veranstaltung;
  const changeStatusMutation = trpc.planung.changeStatusForVeranstaltungen.useMutation({
    onError: () => {
      enqueueSnackbar(failBeMessage, { variant: 'error' });
    },
    onSuccess: (mutateResult: ChangeStatusForVeranstaltungenOutput) => {
      void veranstaltungUtils.invalidate();
      if (mutateResult.success || mutateResult.cause === veranstaltungChangeFailedCause.HEALTH_CHECK) {
        if (mutateResult.successfullyChangedVeranstaltungen.length === 1) {
          enqueueSnackbar(<SuccessMessageSingle veranstaltung={mutateResult.successfullyChangedVeranstaltungen[0]} />, { variant: 'success' });
        }

        if (mutateResult.alreadyInCorrectStatusVeranstaltungen.length === 1) {
          enqueueSnackbar(<AlreadyCorrectMessageSingle veranstaltung={mutateResult.alreadyInCorrectStatusVeranstaltungen[0]} />, { variant: 'info' });
        }

        if (mutateResult.changeFailedVeranstaltungen.length === 1) {
          enqueueSnackbar(<FailCheckMessageSingle veranstaltung={mutateResult.changeFailedVeranstaltungen[0]} />, { variant: 'error' });
        }
      } else if (mutateResult.cause === veranstaltungChangeFailedCause.DATABASE) {
        enqueueSnackbar(failDbMessage, { variant: 'error' });
      }
    },
    onSettled: () => {
      setVivaStatus(veranstaltung.vivaStatus);
    },
  });

  const changeGeschaeftsbereichMutation = trpc.veranstaltung.mutateGeschaeftsbereich.useMutation({
    onSuccess: () => {
      void veranstaltungUtils.invalidate();
      enqueueSnackbar({ variant: 'success', message: 'Geschäftsbereich erfolgreich geändert.' });
    },
    onError: () => {
      enqueueSnackbar({ variant: 'error', message: 'Der Geschäftsbereich konnte nicht geändert werden.' });
      setGeschaeftsbereich(veranstaltung.geschaeftsbereich);
    },
  });

  useEffect(() => {
    setVivaStatus(veranstaltung.vivaStatus);
    setGeschaeftsbereich(veranstaltung.geschaeftsbereich);
  }, [veranstaltung]);

  const onSave = async (): Promise<void> => {
    if (vivaStatus !== veranstaltung.vivaStatus && typeof vivaStatus !== 'undefined' && vivaStatus !== null) {
      await changeStatusMutation.mutateAsync({
        newStatus: vivaStatus,
        idsToChange: [veranstaltung.id],
      });
    }

    if (geschaeftsbereich !== veranstaltung.geschaeftsbereich && typeof geschaeftsbereich !== 'undefined') {
      await changeGeschaeftsbereichMutation.mutateAsync({
        veranstaltungId: veranstaltung.id,
        geschaeftsbereich,
      });
    }
  };

  const onCancel = (): void => {
    setVivaStatus(veranstaltung.vivaStatus);
    setGeschaeftsbereich(veranstaltung.geschaeftsbereich);
  };

  return (
    <Draggable id="eigenschaften">
      <AccordionWithHeading heading="Eigenschaften">
        <Stack paddingLeft={2} spacing={1}>
          {veranstaltung.vivaStatus && veranstaltung.vivaStatus !== vivaStatusEnum.FREIGEGEBEN && (
            <EditButtonGroup isEditMode={isEditMode} setEditMode={setIsEditMode} onSave={onSave} onCancel={onCancel} />
          )}
          <Tablelike
            rows={[
              // ['Sap-Status:', terminQuery.data.sapStatus ?? '-'],
              [
                'Viva-Status:',
                <TextOrSelect
                  key={v4()}
                  isEdit={isEditMode}
                  valueState={[vivaStatus, setVivaStatus]}
                  selections={[
                    { value: vivaStatusEnum.INPLANUNG, name: 'In Planung' },
                    { value: vivaStatusEnum.ABGESCHLOSSEN, name: 'Planung abgeschlossen' },
                    { value: vivaStatusEnum.FREIGEGEBEN, name: 'Planung freigegeben' },
                  ]}
                />,
              ],
              [
                'Geschäftsbereich:',
                <TextOrSelect
                  key={v4()}
                  isEdit={false}
                  valueState={[geschaeftsbereich, setGeschaeftsbereich]}
                  selections={[
                    { value: geschaeftsbereiche.IHC, name: geschaeftsbereiche.IHC },
                    { value: geschaeftsbereiche.ILS, name: geschaeftsbereiche.ILS },
                    { value: geschaeftsbereiche.KFF, name: geschaeftsbereiche.KFF },
                    { value: geschaeftsbereiche.CAS, name: geschaeftsbereiche.CAS },
                    { value: geschaeftsbereiche.DLS, name: geschaeftsbereiche.DLS },
                    { value: undefined, name: '-' },
                  ]}
                />,
              ],
            ]}
          />
        </Stack>
      </AccordionWithHeading>
    </Draggable>
  );
};
