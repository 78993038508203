import { AbstractCalendarEvent, type BlockingData } from './AbstractCalendarEvent';
import { resourcenBlockungTypes, type StandortSlot } from 'dtos';
import { type TerminInfo } from 'utils/TerminInfo';

type CalendarSlotEventData = BlockingData & {
  standortName: string;
  gruppenraum: boolean;
  hotelqualifikationen: string;
  raumgroesse: number;
};

export class CalendarSlotEvent extends AbstractCalendarEvent {
  public readonly type = resourcenBlockungTypes.SLOT;

  public resource: `s-${string}`;

  public standortSapId: number;

  public data: CalendarSlotEventData;

  public constructor(
    start: Date,
    end: Date, // don't import the real CalendarResource type here - otherwise, there will by cyclic includes
    calendarResource: {
      id: `s-${string}`;
      getDataId: () => number;
      terminInfo: Pick<TerminInfo, 'id' | 'sapid' | 'division' | 'ablauf'>;
      data: Partial<StandortSlot> & { standortId: number; standortName: string };
    },
    data: Omit<CalendarSlotEventData, 'ablauf' | 'standortName'>,
  ) {
    super(start, end, calendarResource);
    this.resource = calendarResource.id;
    this.standortSapId = calendarResource.data.standortId;

    this.data = {
      standortName: calendarResource.data.standortName,
      gruppenraum: data.gruppenraum,
      raumgroesse: data.raumgroesse,
      hotelqualifikationen: data.hotelqualifikationen,
      quelle: data.quelle,
      terminId: data.terminId ?? String(calendarResource.terminInfo.id),
      division: data.division ?? calendarResource.terminInfo.division,
      ablauf: [{ start, end }],
    };
  }
}
