import { content } from './TerminExperteCard.content';
import { type ExperteHiveTerminanfrageConflict, type HiveTerminanfrageStatus, hiveTerminanfrageStatus } from 'dtos';
import { formatTimeRangeForDate } from 'utils/dateFormat';

export const getLabelAndTooltip = (status: HiveTerminanfrageStatus | null, additional?: string): { label: string; tooltip: string } => {
  // based on the status, return the correct content

  switch (status) {
    case hiveTerminanfrageStatus.MANUAL:
      return content.terminanfrageHiveStatus.manual;
    case hiveTerminanfrageStatus.PENDING:
      return content.terminanfrageHiveStatus.pending;
    case hiveTerminanfrageStatus.ACCEPTED:
      return content.terminanfrageHiveStatus.accepted;
    case hiveTerminanfrageStatus.REJECTED:
      if (typeof additional !== 'string') {
        throw new TypeError('[TerminExperteCard - getStatusSpecificContent] HiveTerminanfrageStatus was REJECTED, but no rejection reason was given.');
      }

      return content.terminanfrageHiveStatus.rejected(additional);
    case hiveTerminanfrageStatus.CONFLICT:
      if (typeof additional !== 'string') {
        throw new TypeError('[TerminExperteCard - getStatusSpecificContent] HiveTerminanfrageStatus was REJECTED, but no information about conflicting Veranstaltungen was given.');
      }

      return content.terminanfrageHiveStatus.conflict(additional);
    case hiveTerminanfrageStatus.CANCELLED:
      return content.terminanfrageHiveStatus.cancelled;
    case null:
      return {
        label: content.noInfoAvailable,
        tooltip: content.noInfoAvailable,
      };
    default:
      throw new Error('[TerminExperteCard - getStatusSpecificContent] HiveTerminanfrageStatus was in an unhandled state.');
  }
};

export const printConflicts = (conflicts: ExperteHiveTerminanfrageConflict[]): string => {
  if (conflicts.length === 0) {
    throw new Error('[TerminExperteCard - printConflicts] Function was called without a list of conflicts.');
  }

  let print = '';

  // go over all the conflicts - each conflict represents one Veranstaltung
  for (let i = 0; i < conflicts.length; i++) {
    const conflict = conflicts[i];
    print += `Veranstaltung ${conflict.originId} in den Zeiträumen `;

    // check if we have a valid list of conflictTermine on the Veranstaltung
    // each conflictTermin represents an Experteblockung which has the same Experte for an overlapping time interval
    const conflictTermine = conflict.conflictTermine;
    if (typeof conflictTermine === 'undefined' || conflictTermine.length === 0) {
      throw new Error(`[TerminExperteCard - printConflicts] Function was called with an invalid list of conflictTermine on ${conflict.originId}`);
    }

    // go over each conflictTermin on the Veranstaltung, and print the time interval
    for (let j = 0; j < conflictTermine.length; j++) {
      const conflictTermin = conflictTermine[j];
      print += `${formatTimeRangeForDate(conflictTermin.start, conflictTermin.end)}`;

      // if it's the last conflictTermin, we don't need to print a comma afterwards - otherwise, we need to print it
      if (j !== conflictTermine.length - 1) {
        print += ', ';
      }
    }

    // print quelle of the Veranstaltung
    print += ` (Veranstaltungsquelle ist ${conflict.origin})`;

    // if it's the last Veranstaltung, we don't need to print another divider - otherwise, we need to print it
    if (i !== conflicts.length - 1) {
      print += ' | ';
    }
  }

  // example: 'Veranstaltung V1 in den Zeiträumen 03.09.2024, 10:01 - 14:01 (Veranstaltungsquelle ist AKA.BAN.CESAR.SALESFORCE) | Veranstaltung V2 in den Zeiträumen 02.09.2024, 11:07 - 17:07, 03.09.2024, 11:08 - 17:08 (Veranstaltungsquelle ist AKA.BAN.VMOS)',
  return print;
};
